<template>
  <div class="container">
    <div class="page-header">
      <div class="page-header__ell">
        <h1 class="page-title">Добавить пользователя</h1>
      </div>
      <!--<div class="page-header__ell">-->
      <!--  <b-form-checkbox v-model="checked" name="check-button" size="lg" switch>-->
      <!--    Включить / отключить-->
      <!--  </b-form-checkbox>-->
      <!--</div>-->
    </div>
    <ClientEditForm ref="userAddForm" />
    <form class="form">
      <div class="form__btn-gp">
        <b-button size="lg" variant="danger" @click="save">Сохранить</b-button>
        <router-link :to="{name: this.$routeName.CLIENT_LIST}" class="btn btn-link btn-lg">Отмена</router-link>
      </div>
    </form>
  </div>
</template>

<script>

import ClientEditForm from "@component/Form/ClientEditForm";

export default {
  name: "ClientAdd",
  components: {
    ClientEditForm,
  },
  data() {
    return {
      checked: false,
    };
  },
  methods: {

    async save() {

      let formData = this.$refs.userAddForm.formSubmitGetData();
      if(!formData) { return; }

      let sendData = Object.assign({}, formData);
      sendData.mobile = sendData.mobile.replace(/-|\s+/g,"");
      RequestManager.Client.addClient(sendData).then( (res) => {

        // TODO: fix for user slot
        if(sendData.slotEnable) {

          let data = {
            owner     : res.self_family,
            status    : 'FREE',
            // span      : sendData.span,
            // repeat    : sendData.repeat.id,
            date      : sendData.date,
            start_time: sendData.start_time,
            end_time  : sendData.end_time,
          };

          if(sendData.repeat.id !== 'ONCE') {
            data.repeat = sendData.repeat.id;
            data.span   = sendData.span.id;
          }

          RequestManager.Client.addClientSlot({
            clientId: res.id,
            data     : data
          });
        }


        this.$toasts.push({
          message: 'Пользователь успешно добавлен',
          duration: 4000,
        });
        this.$router.push({name: this.$routeName.CLIENT_LIST });
      });

    }
  }
};
</script>

<style lang="scss" scoped>

</style>
